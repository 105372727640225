import React, { useState, useEffect } from 'react';
import AddCard from './card/addCard';
import '../Style/Donate.css'

const Donate = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showDonationAmount, setShowDonationAmount] = useState(true);
    const [donationAdded, setDonationAdded] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [displayedValue, setDisplayedValue] = useState('');
  
    useEffect( () => {
        setIsOpen(true)
      }, []);

    const handleAmountSubmit = (e) => {
        e.preventDefault();
        if (parseInt(inputValue) < 20) {
            alert('Amount must be at least R20 or more');
            return;
        }
        else{
            setShowDonationAmount(false);
            setDonationAdded(true)
        }
        // Handle submission logic here
        console.log('Submitted value:', inputValue);
    };

    const handleAmountInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setDisplayedValue(value);
    };
    
    const DonationAmount = () => {
    return (
        <div className={`amount-panel ${showDonationAmount ? 'show' : ''}`}>
                <form onSubmit={handleAmountSubmit}>
                    <label className="input-label" htmlFor="numberInput">
                        Enter Donation Amount (min: R20):
                    </label>
                    <input
                        className="input-field"
                        type="number"
                        id="numberInput"
                        name="numberInput"
                        value={inputValue}
                        onChange={handleAmountInputChange}
                        min="20"
                        required/>
                    <br/>
                    <label className="input-label" htmlFor="displayNumber">
                        You entered:
                    </label>
                    <p id="displayNumber">R{displayedValue}</p>
                    <br />
                    <button className="confirm-button" type="submit">
                        Confirm
                    </button>
                </form>
        </div>
    );
    }
  return (
    <div>
        {
            showDonationAmount?
            DonationAmount()
            : 
            <div>
            </div>
        }
     <div className={`donation-page ${isOpen ? 'open' : ''}`}>
        <div className="donation-info">
            <h2>Why Donate?</h2>
             <p>
                We rely on your generous donations to keep our website ad-free
                 and provide high-quality content. Your support helps us continue
                 delivering a great user experience.
            </p>
         </div>
        <div className="donation-form">
         <h1>Donate R{inputValue}</h1>
            <AddCard donationAmount = {inputValue}/>
        </div>
    </div>
    </div>
    );
};
    
export default Donate;

