import logo from './logo.svg';
import './App.css';
import WelcomePage from './components/WelcomePage';
import Typist from 'react-typist';
import { ConnectWithoutContactSharp } from '@mui/icons-material';

function App() {
  return (
    <div className="App">
        <WelcomePage/>  
    </div>
  );
}

export default App;
